import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./App.css";
import NavBar from "./components/NavBar";
import ChatBox from "./components/ChatBox";
import Welcome from "./components/Welcome";
// import axios from "axios";
// import { useState } from "react";

function App() {
    const [user] = useAuthState(auth);

    return (
        <div className="App">
            <NavBar />
            {!user ? (
                <Welcome />
            ) : (
                <>
                    <ChatBox />
                </>
            )}
            {/* <FileUpload></FileUpload> */}
        </div>
    );
}

// const FileUpload = () => {
//     const [file, setFile] = useState(null);

//     const submitFile = async (event) => {
//         event.preventDefault();
//         const formData = new FormData();
//         formData.append("file", file);

//         // adjust the URL as necessary
//         const url = `http://127.0.0.1:5906/dfv-1859d4ce-b4f0-4df0-8df5-d4513181bb77-sc?name=${file.name}`;

//         try {
//             const res = await axios.post(url, formData, {
//                 headers: {
//                     "Content-Type": "multipart/form-data",
//                 },
//             });
//             console.log(res.data);
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const handleFileUpload = (event) => {
//         setFile(event.target.files[0]);
//     };

//     return (
//         <form onSubmit={submitFile}>
//             <label htmlFor="upload">Upload a file:</label>
//             <input type="file" id="upload" onChange={handleFileUpload} />
//             <button type="submit">Submit</button>
//         </form>
//     );
// };

export default App;
