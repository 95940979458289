// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC55bnHT_13ftqusltOifsB1m7WrhlcvDA",
    authDomain: "auth.sqrxinternaldev.com",
    projectId: "sqrx-dev-interns",
    messagingSenderId: "372059388219",
    appId: "1:372059388219:web:ce76f759ff10488b4d5f1e",
    measurementId: "G-FDTEHMHJM1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
